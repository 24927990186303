<template>
  <v-container fluid>
    <v-row>
      <v-col md="10" offset-md="1">
        <v-card
            class="pa-2"
            shaped>
          <v-card-title>Vendor and Shipment Overview</v-card-title>
            <v-card-text dense>
              <v-row>
                <v-col
                    cols="6"
                >
                  <v-card-title>
                    Vendor
                  </v-card-title>
                  <v-combobox
                      :items="vendors.vendors"
                      item-text="CompanyName"
                      return-object
                      outlined
                      dense
                      label="Select Vendor"
                  ></v-combobox>
                  Address: {{ getVendorAddress() }}
                </v-col>
                <v-divider vertical></v-divider>
                <v-col cols="6">
                  <v-card-title>
                    Ship To
                  </v-card-title>
                  <v-combobox
                      :items="vendors.vendors"
                      item-text="CompanyName"
                      return-object
                      outlined
                      dense
                      label="Select Shipping Location"
                  ></v-combobox>
                </v-col>
              </v-row>
            </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {

  name: 'PurchaseOrderHeader',
  props: {
    vendors: []
  },
  data () {
    return {
      orderNumber: '',
      deliveryDate: '',
      notes: '',
      title: 'Create Purchase Order',
      purchaseOrder: {},
      alert: false,
      alertText: '',
      selectedVendor: {},
      selectedShipToLocation: {}
    }
  },
  methods: {
    getVendorAddress: function() {
      return this.selectedVendor.Address;
    },
    getSelectedVendor: function() {
      if (this.selectedVendor.CompanyName) {
        return this.selectedVendor.CompanyName;
      }

      return "";
    }
  },
  mounted: async function() {

  }
}
</script>

<style scoped>

</style>