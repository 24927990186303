<template>
  <div class="container">
  </div>
</template>
<style>
</style>
<script>
export default {
  name: 'OrderWidget',
  props: {
    title: String,
    content: String,
    severity: String,
  },
  data () {
    return {
      headerColor: 'black'
    }
  },
  methods: {

  },
  mounted: function () {
  }
}
</script>
<style>
</style>