<template>
  <v-container fluid>
    <v-row>
      <v-col md="10" offset-md="1">
        <v-card
            class="mx-auto"
            shaped>
          <v-card-title>
            Shipment Details
          </v-card-title>
          <v-card-text>
          <v-form>
            <v-menu
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                    dense
                    label="Estimated Delivery"
                    v-model="deliveryDate"
                    hint="MM/DD/YYYY"
                    persistent-hint
                    prepend-icon="mdi-calendar"
                    v-bind="attrs"
                    v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                  v-model="deliveryDate"
                  no-title
              ></v-date-picker>
            </v-menu>
          </v-form>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<style>
</style>
<script>
import vendors from '../../../data/vendors.json';

export default {

  name: 'PurchaseOrderDetails',
  data () {
    return {
      orderNumber: '',
      deliveryDate: '',
      notes: '',
      title: 'Create Purchase Order',
      purchaseOrder: {},
      alert: false,
      alertText: '',
      vendors: vendors,
    }
  },
  methods: {
    save: function() {
      /**
       * {
       *   "dateCreated": "2023-11-22T23:19:08.438Z",
       *   "orderNumber": "ORD0001234",
       *   "contactName": "string",
       *   "department": "string",
       *   "phoneNumber": "string",
       *   "discountPercent": 0,
       *   "taxRate": 0,
       *   "shippingHandlingFee": 0,
       *   "notes": "string"
       * }
       * @type {Object}
       */

      this.purchaseOrder.orderNumber = this.orderNumber;

      if (this.deliveryDate == '') {
        alert("You must select a delivery date");
      }

      this.purchaseOrder.dateCreated = this.deliveryDate;
      this.purchaseOrder.department = "Warehouse Inventory";
      this.purchaseOrder.phoneNumber = "616-654-0912";
      this.purchaseOrder.discountPercent = 0.1;
      this.purchaseOrder.taxRate = 0.06;
      this.purchaseOrder.shippingHandlingFee = 2.15;
      this.purchaseOrder.notes = this.notes;
      const appsUrl = 'http://' + this.$host + ':' + this.$inventoryPort + '/api/inventory-management/PurchaseOrder';
      this.$http.post(appsUrl, purchaseOrder)
          .then((result) => {
          });

      location.href = '#/inventory/purchaseOrders';
    },
    back: function() {
      location.href = '#/inventory/purchaseOrders';
    },
  },
  mounted: async function() {
  }
}
</script>
<style>
</style>