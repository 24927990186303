<template>
  <div>
    <v-alert
        v-show="isAlertVisible"
    ></v-alert>
    <h3>Identify Putaway Cart</h3>
    <v-text-field
        v-model="containerId"
        placeholder="Scan or enter container LPN."
        v-on:keyup.enter="confirm()"
        autofocus
    />
    <v-btn v-on:click="confirm()" v-on:keyup.enter="confirm()">Confirm</v-btn>
  </div>
</template>
<script>
export default {
    name: 'BuildPutawayCart',
    props: {
        msg: String
    },
    data() {
      return {
        userId: 'test1',
        zoneId: 'zone1',
        containerId: '',
        currentState: '',
        putawayCart: '',
        dismissCountDown: 0,
        dismissSeconds: 5,
        variant: "success",
        tempMessage: "",
        isAlertVisible: false
      }
    },
    methods: {
        countDownChanged: function(dismissCountDown) {
            this.dismissCountDown = dismissCountDown;
        },
        confirm: function() {
            if (this.containerId == '') {
                this.tempMessage = 'Please identify a container.';
                this.variant = "warning";
                return;
            }

            const appsUrl = 'http://' + this.$host + ':' + this.$putawayPort + '/api/putaway/Putaway/cart/' + this.containerId;
            this.$http.post(appsUrl)
                .then((response) => {
                    this.putawayCart = response.data;
                    if (this.putawayCart.putaways != null && this.putawayCart.putaways.length > 0) {
                        location.href = '#/putaway/cart/' + this.containerId + '/induct';
                    } else {
                        location.href = '#/putaway/cart/' + this.containerId + '/addPutawayToCart';
                    }
                })
                .catch(function (error) {
                    this.tempMessage = error.response.data;
                    this.dismissCountDown = 5;
                });
        },
    },  
}
</script>