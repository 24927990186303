<template>
  <div class="login">
    <div class="mt-3">
        <b-row>
            <h4>Login to Zone</h4>
        </b-row>
        <b-row>
            <b-input-group class="mb-2">
                <b-input
                    v-model="zoneId"
                    placeholder="Scan or enter zone."
                    v-on:keyup.enter="confirm()"/>
            </b-input-group>
        </b-row>
    </div>
    <div class="mt-3">
        <b-row>
            <b-button-group>
                <b-button v-on:click="confirm()" v-on:keyup.enter="confirm()">Confirm</b-button>
            </b-button-group>
        </b-row>
    </div>
  </div>
</template>
<script>
import * as users from "../../../js/users.js";

export default {
    name: 'PickingLogin',
    props: {
        msg: String
    },
    data() {
        return {
            zoneId: '',
            currentState: '',
            pickingCart: '',
            dismissCountDown: 0,
            dismissSeconds: 5,
            variant: "success",
            tempMessage: "",
        }
    },
    methods: {
        countDownChanged: function(dismissCountDown) {
            this.dismissCountDown = dismissCountDown;
        },
        confirm: function() {
            if (this.zoneId == '') {
                this.tempMessage = 'Please identify a zone.';
                this.variant = "warning";
                this.dismissCountDown = 5;
                return;
            }

            let createSessionRequest = new Object();
            createSessionRequest.userName = users.getUserName();
            createSessionRequest.zoneId = this.zoneId;
            const appsUrl = 'http://' + this.$host + ':' + this.$pickingPort + '/api/picking/v0.1/Session';
            this.$http.post(appsUrl, createSessionRequest)
                .then((response) => {
                    location.href = '#/picking/cart/build';
                })
                .catch(function (error) {
                    this.tempMessage = error.response.data;
                    this.dismissCountDown = 5;
                });
        },
    },  
}
</script>