<template>
  <div style="left=2%; right=2%;">
    <CreateEditToolbar
        :title="title + deliveryId"
    />
    <v-text-field
          label="Delivery"
          placeholder="Placeholder"
          v-model="deliveryId" @input="fetchDelivery"
          
    ></v-text-field>
    <v-stepper>
      <v-stepper-header>
        <v-stepper-step step="1" :complete="deliveryStatus > -1">Created</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="2" :complete="deliveryStatus > 0">Arrived</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="3" :complete="deliveryStatus > 1">Active</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="4" :complete="deliveryStatus> 3">Completed</v-stepper-step>
      </v-stepper-header>
    </v-stepper>
    <h4>Arrival: {{ formatDate(delivery.arrivalDate) }}</h4>
    <h3>Containers</h3>
    <v-data-table
      :headers="containerHeaders"
    >
    </v-data-table>
    <v-data-table
      :headers="headers"
      :items="delivery.containers"
      multi-sort>
      <template v-slot:item.state="{ item }">
        <v-row>
          {{ formatState(item.state) }}
        </v-row>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import CreateEditToolbar from "@/components/Base/CreateEditToolbar.vue";

export default {
  name: 'DeliveryDetails',
  components: {CreateEditToolbar},
  props: {
    msg: String
  },
  data() {
      return {
        title: "Delivery Details - ",
        deliveryId: '',
        delivery: {},
        deliveryStatus: 0,
        breadcrumbs: ['Inbound', 'Deliveries', 'Delivery Lookup'],
        containerHeaders: [
          { text: "Container", value: "licensePlateNumber" }
        ],
        headers: [
          { text: "Container", value: "licensePlateNumber" },
          { text: "Status", value: "state" },
          { text: "Drop Off", value: "dropOffLocation"},
          { text: "Items", value: "deliveryItems.length"}
        ]
      }
  },
  methods: {
      formatState: function(state) {
        this.deliveryStatus = state;
        if (state === 0) {
            return 'Created';
        } else if (state === 1) {
            return 'Arrived';
        } else if (state === 2) {
            return 'Suspended';
        } else if (state === 4) {
            return 'Completed';
        }
      },
      formatDate(date) {
        let newDate = new Date(date);
        return newDate.toLocaleString();
      },
      formatContainerState: function(state) {
          if (state === 0) {
            return 'Created';
        }

        if (state === 1) {
            return 'Arrived';
        }

        if (state === 2) {
            return 'Accepted';
        }

        if (state === 3) {
            return 'Suspended';
        }

        if (state === 4) {
            return 'Completed';
        }
      },
      fetchDelivery: function() {
        this.delivery = {};
        const appsUrl = 'http://' + this.$host + ':' + this.$receivingPort + '/api/receiving/Delivery/' + this.deliveryId
          this.$http.get(appsUrl)
            .then((result) => {
              this.delivery = result.data;
              this.formatState(this.delivery.state);
            });
      },
      createDeliveryContainer: function() {
        location.href = '#/delivery/' + this.deliveryId + '/createDeliveryContainer';
      }
  },

  mounted: function () {
      this.deliveryId = this.$route.params.deliveryId;
      this.fetchDelivery();
  }
}
</script>