<template>
  <v-container fluid>
    <v-row>
      <v-col md="5" offset-md="1">
        <v-card
            class="pa-2"
            shaped>
          <v-card-title>Notes</v-card-title>
          <v-card-text>
            <v-textarea
                label="Notes"
                v-model="notes"
            >
            </v-textarea>

          </v-card-text>
        </v-card>
      </v-col>
      <v-col md="5" offset-md="0">
        <v-card
            class="pa-2"
            shaped>
          <v-card-title>Notes</v-card-title>
          <v-card-text>
            <v-textarea
                label="Notes"
                v-model="notes"
            >
            </v-textarea>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {

  name: 'PurchaseOrderFooter',
  props: {
    vendors: []
  },
  data () {
    return {
      orderNumber: '',
      deliveryDate: '',
      notes: '',
      title: 'Create Purchase Order',
      purchaseOrder: {},
      alert: false,
      alertText: '',
      selectedVendor: {},
    }
  },
  methods: {
  },
  mounted: async function() {

  }
}
</script>

<style scoped>

</style>