<template>
  <div>
    <div>
      <h2>Roles</h2>
      <v-data-table
          :items="users"
          :headers="userHeaders"
      >
        <template
            v-slot:item.name="{ item }"
        >
          <v-row v-on:click="onItemClick(item.name)">
            {{ item.name }}
          </v-row>
        </template>
      </v-data-table>
    </div>
    <v-navigation-drawer
        right
        v-model="drawer"
        absolute
        temporary
        width="600"
        height="900px"
    >
      <RolesPermissions/>
    </v-navigation-drawer>

  </div>

</template>

<script>

import RolesPermissions from "@/components/WarehouseConfiguration/RolesPermissions";
export default {
  name: 'Roles.vue',
  props: {
    msg: String
  },
  components: {
    RolesPermissions
  },
  data() {
    return {
      users: [],
      drawer: true,
      userHeaders: [
        { text: 'Role', value: 'name' },
        { text: "Description", value: "description" },
        { text: "Users Assigned", value: "userCount" },
        { text: "Permissions Assigned", value: "permissionCount" }
      ]
    }
  },
  methods: {
    onItemClick: function(value) {
      this.drawer = !this.drawer;
}
  },
  mounted: function () {
    const appsUrl = 'http://' + this.$host + ':' + this.$userManagementPort + '/api/user-management/role';
    this.$http.get(appsUrl)
        .then((result) => {
          this.users = result.data;
        });
  }
}
</script>