<template>
  <div class="container" width=300>
    <h1>Performance Metrics</h1>
    </br>
    <b-table width="300" :bordered=true responsive='sm' :small=true :items='responseTimeMetrics'>
    </b-table>
  </div>
    </template>
    <style>
    </style>
    <script>
        export default {
  name: 'OrderWidget',
  data () {
    return {
        responseTimeMetrics: []
    }
  },
  methods: {
    getResponseTimeMetrics: function() {
        const appsUrl = 'http://' + this.$host + ':' + this.$simulationPort + '/getResponseTimeMetrics'
        this.$http.get(appsUrl)
            .then((result) => {
                this.responseTimeMetrics = result.data['response_time_metrics_list'];
            })
    }
  },
  mounted: function () {   
    this.getResponseTimeMetrics();
  }
}
</script>
<style>
</style>