import axios from 'axios';
import * as configurations from "@/js/configurations";

export function getStatus(status) {
    if (status === 0) {
        return 'Created';
    }

    if (status === 1) {
        return 'Received';
    }

    if (status === 2) {
        return 'Available';
    }

    if (status === 3) {
        return 'Unavailable';
    }

    return "Unknown";
}

function getInventoryApiUrl() {
    let appsUrl = 'http://' + configurations.getHostUrl() + ':' + configurations.getPort() + '/api/inventory-management/';
    return appsUrl;
}

export async function getPurchaseOrders() {
    const appsUrl = getInventoryApiUrl() + 'PurchaseOrder';
    let result = await axios.get(appsUrl);
    return result.data;
}