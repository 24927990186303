import axios from 'axios';
import * as configurations from './configurations.js';

export function formatDeliveryState(state) {
    if (state === 0) {
        return 'Created';
    }

    if (state === 1) {
        return 'Arrived';
    }

    if (state === 2) {
        return 'Suspended';
    }

    if (state === 4) {
        return 'Completed';
    }
}

export function formatDeliveryContainerState(state) {
    if (state === 0) {
        return 'Created';
    }

    if (state === 1) {
        return 'Arrived';
    }

    if (state === 2) {
        return 'Accepted';
    }

    if (state === 3) {
        return 'Suspended';
    }

    if (state === 4) {
        return 'Completed';
    }
}

function getDeliveryApiUrl() {
    let appsUrl = 'http://' + configurations.getHostUrl() + ':' + configurations.getPort() + '/api/receiving/ReceivingTask';
    return appsUrl;
}

/**
 * Returns a list of deliveries from the web service.
 * @returns {Promise<void>}
 */
export async function getDeliveries() {
    let appsUrl = getDeliveryApiUrl();
    let result = await axios.get(appsUrl);
    let deliveries = result.data;
    for (let i = 0; i < deliveries.length; i++) {
        deliveries[i].incompleteContainers = 0;
        deliveries[i].completeContainers = 0;
        for (let j = 0; j < deliveries[i].containers.length; j++) {
            if (deliveries[i].containers[j].state != 4) {
                deliveries[i].incompleteContainers += 1;
            } else {
                deliveries[i].completeContainers += 1;
            }
        }
    }

    return deliveries;
}