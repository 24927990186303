<template>
  <div class="container">
        <v-card v-for="element in elements"
                v-bind:key="element.title"
                :border-variant=element.severity
                :header-border-variant=element.severity
                :header-text-variant=element.severity
                :header=element.title
                :header-bg-variant=severity
                >
            <h4 class="text-center">{{ element.content }}</h4>
        </v-card>
  </div>
</template>
<style>
</style>
<script>
export default {
  name: 'OrderWidget',
  props: {
    elements: Array,  
  },
  data () {
    return {
      headerColor: 'black'
    }
  },
  methods: {
    
  },
  mounted: function () {
    if (this.severity === 'danger') {
      this.headerColor = 'white';
    }
  }
}
</script>
<style>
</style>