<template>
  <div>
    <v-toolbar
        color="grey"
        dark
        dense
        flat
    >
      <v-toolbar-title class="text-md-body-1">
        {{ title }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
      ></v-text-field>
      <v-spacer></v-spacer>
      <v-btn
          small
          disabled
          color="grey-darken-3"
          class="ma-2 white--text"
      >
        <v-icon
            left
            dark
        >
          mdi-pencil
        </v-icon>
        Create
      </v-btn>
    </v-toolbar>
    <v-data-table
      :items="orders"
      :headers="headers"
      :search="search"
      :loading="isLoading"
      no-data-text="No data"
      loading-text="Loading... Please wait"
      dense
      class="elevation-1"
      @row-clicked="navToOrderDetails"
    >
      <template
        v-slot:item.state="{ item }"
      >
        <v-row>
          {{ formatState(item.state) }}
        </v-row>
      </template>
      <template
          v-slot:item.orderId="{ item }"
      >
        <v-row v-on:click="navToOrderDetails(item.orderId)">
          {{ item.orderId }}
        </v-row>
      </template>
      <template v-slot:item.latestShipTime="{ item }">
        <v-row>
          {{ formatDate(item.latestShipTime) }}
        </v-row>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import * as orders from '../js/orders.js';

export default {
  name: 'Orders',
  props: {
    msg: String
  },
  data() {
    return {
      orders: [],
      title: "Orders",
      headers: [
        { value: 'orderId', text: 'Order' },
        { value: 'state', text: 'State' },
        { value: 'priority', text: 'Priority' },
        { value: 'latestShipTime', text: 'Ship Time' }
      ],
      breadcrumbs: [
        { text: "Home", href: "/" },
        { text: "Operations" },
        { text: "Orders" }
      ],
      currentPage: 1,
      totalRows: 1,
      rowsPerPage: 20,
      filter: null,
      search: '',
      isLoading: true,
    }
  },
  methods: {
      formatDate(value) {
        let date = new Date(value);
        let formattedDate = date.toLocaleString();
        return formattedDate;
      },
      formatState: function(state) {
        let formattedState = orders.getStateStringFormat(state);
        return formattedState;
      },
      onFiltered(filteredItems) {
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      navToOrderDetails(item, index, event) {
        location.href = '#/orders/' + item;
      }
  },
  mounted: function () {
      const appsUrl = 'http://' + this.$host + ':' + this.$ordersPort + '/api/order-management/Order';
      this.$http.get(appsUrl)
        .then((result) => {
          this.orders = result.data;
          this.isBusy = false;
          this.totalRows = this.orders.length;
          this.isLoading = false;
        })
        .catch(function(error) {
          console.error('Encountered the following error:', error.toJSON());
        })
  }  
}
</script>