<template>
  <v-card height="600px">
    <template v-slot:prepend>
      <v-list-item two-line>
        <v-list-item-avatar>
          <img src="https://randomuser.me/api/portraits/women/81.jpg">
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>Jane Smith</v-list-item-title>
          <v-list-item-subtitle>Logged In</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>
    <v-divider></v-divider>
    <v-list dense>
      <v-list-item
          v-for="item in users"
          :key="item.name"
      >
        <v-list-item-content>
          <v-list-item-title>{{ item.description }}</v-list-item-title>
        </v-list-item-content>
        <v-switch
            v-model="item.isAssigned"
            :label="`Switch 1: ${item.isAssigned.toString()}`"
        ></v-switch>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script>

export default {
  name: 'RolesPermissions.vue',
  props: {
    msg: String
  },
  components: {
  },
  data() {
    return {
      users: [],
      switch1: false,
      drawer: true,
      userHeaders: [
        { text: 'Permission', value: 'name' },
        { text: "Category", value: "category" },
        { text: "Description", value: "description" },
      ]
    }
  },
  methods: {
  },
  mounted: function () {
    const appsUrl = 'http://' + this.$host + ':' + this.$userManagementPort + '/api/user-management/permission';
    this.$http.get(appsUrl)
        .then((result) => {
          this.users = result.data;
          for (let i = 0; i < this.users.length; i++) {
            this.users[i].isAssigned = false;
          }
        });


  }
}
</script>