<template>
  <v-list
    nav
    dense
  >
    <v-list-group
      v-for="item in items"
      :key="item.title"
      v-model="item.active"
      :prepend-icon="item.action"
      no-action
    >
      <template v-slot:activator>
        <v-list-item-content>
          <v-list-item-title v-text="item.title"></v-list-item-title>
        </v-list-item-content>
      </template>
      <v-list-item
        v-for="child in item.items"
        :key="child.title"
        link
        :href="child.link"
      >
        <v-list-item-content>
          <v-list-item-title v-text="child.title" ></v-list-item-title>
        </v-list-item-content>
    </v-list-item>
  </v-list-group>
</v-list>
</template>
<script>
export default {
    name: 'Nav',
    isVisible: false,
    data: () => ({
      items: [
        {
          title: 'Dashboards',
          action: 'mdi-monitor-dashboard',
          items: [
            { title: 'Warehouse Overview', link: '#/warehouseOverview' }
          ]
        },
        {
          title: 'Receiving',
          action: 'mdi-truck-delivery',
          items: [
            { title: 'Deliveries', link: '#/delivery' },
            { title: 'Putaways', link: '#/putaways'},
          ],
        },
        {
          title: 'Inventory',
          action: 'mdi-application-cog',
          items: [
            { title: 'Inventory Lookup', link: '#/inventoryLookup' },
          ],
        },
        {
          title: 'Orders',
          action: 'mdi-briefcase',
          items: [
            { title: 'Orders', link: '#/orders'},
          ],
        },
        {
          title: 'Workflows',
          action: 'mdi-briefcase',
          items: [
            { title: 'Receive Deliveries', link: '#/delivery/inductDeliveryContainer' },
        //     { title: 'Putaway Inventory', link: '#/putaway/cart/buildPutawayCart' },
        //     { title: 'Pick Orders', link: '#/picking/loginZone'},
          ],
        },
        {
          title: 'Administration',
          action: 'mdi-application-cog',
          items: [
            { title: 'Warehouse Locations', link: '#/administration/warehouseLocations' },
            { title: 'Simulation', link: '#/demo' }
          ],
        },
      ],
    }),
    methods: {
      logout() {
        localStorage.isLoggedIn = false;
        location.href = '#';
      },
    }
}
</script>
<style lang="scss" scoped>
</style>