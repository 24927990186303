<template>
  <div class="login">
    <b-container>
        <b-alert 
          :show="dismissCountDown"
          :variant="variant"
          dismissible
          @dismissed="dismissCountDown==0" 
          @dismiss-count-down="countDownChanged">
          <p>{{ tempMessage }}</p>
          <b-progress
              variant="warning"
              :max="dismissSeconds"
              :value="dismissCountDown"
              height="4px"
          ></b-progress>
        </b-alert>
        <div class="mt-3">
            <b-row>
                <h4>Identify Cart</h4>
            </b-row>
            <b-row>
                <b-input-group class="mb-2">
                    <b-input
                        v-model="cartId"
                        placeholder="Scan or enter cart."
                        v-on:keyup.enter="confirm()"/>
                </b-input-group>
            </b-row>
        </div>
        <div class="mt-3">
            <b-row>
                <b-button-group>
                    <b-button v-on:click="confirm()">Confirm</b-button>
                </b-button-group>
            </b-row>
        </div>
    </b-container>
  </div>
</template>
<script>
export default {
    name: 'PickingLogin',
    props: {
        msg: String
    },
    data() {
        return {
            zoneId: '',
            currentState: '',
            cartId: '',
            dismissCountDown: 0,
            dismissSeconds: 5,
            variant: "success",
            tempMessage: "",
        }
    },
    methods: {
        countDownChanged: function(dismissCountDown) {
            this.dismissCountDown = dismissCountDown;
        },
        confirm: function() {
            if (this.cartId === '') {
                this.tempMessage = 'Please identify a cart.';
                this.variant = "warning";
                this.dismissCountDown = 5;
                return;
            }

            let createClusterRequest = {};
            createClusterRequest.userId = 'openwes';
            createClusterRequest.clusterId = this.cartId;
            const appsUrl = 'http://' + this.$host + ':' + this.$pickingPort + '/api/v1/Cluster';
            this.$http.post(appsUrl, createClusterRequest)
                .then((response) => {
                    location.href = '#/picking/cart/' + this.cartId + '/addContainer';
                })
                .catch(function (error) {
                    console.error('An error occurred while building the cluster: ', error);
                });
        },
    },  
}
</script>