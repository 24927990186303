<template>
  <div class="pick">
    <h4>Go to {{ locationId }}</h4>
    <h4>Pick {{ quantity }}</h4>
    <input v-model="quantity"/>
    <button v-on:click="ready()" class="btn btn-primary">Ready</button>
    <div class="options">
      <button v-on:click="logout()" class="btn btn-primary">Logout</button>
    </div>
  </div>
  
</template>

<script>
export default {
  name: 'Pick',
  props: {
    msg: String
  },
  data() {
      return {
          locationId: '',
          quantity: 0,
          containerId: '',
      }
  },
  methods: {
      ready: function() {
          var containerId = localStorage.getItem('containerId')
          var pickId = this.workList[0].pickId
          var userId = localStorage.getItem('userId')
          const appsUrl = 'http://' + this.$host + ':' + this.$pickingPort + '/Pick?pickId=' + pickId + '&pickContainerId=' + containerId + '&qtyPicked=' + this.quantity + '&pickType=COMPLETE&userId=' + userId
          this.$http.post(appsUrl)
          .then((response) => {
              this.workList.shift()
              if (this.workList.length > 0) {  
                  this.locationId = this.workList[0].locationId
                  this.quantity = this.workList[0].qtyRequested            
                  location.href = '#/rf/pick'
              } else {
                  localStorage.setItem('currentState', 'WORKLIST_COMPLETE')
                  location.href = '#/rf/scan'
              }
          })
      },
      logout: function () {
        var userId = localStorage.getItem("userId")
        var zoneId = localStorage.getItem("zoneId")
        const appsUrl = 'http://' + this.$host + ':' + this.$pickingPort + '/Login/?userName=' + userId + '&zoneId=' + zoneId
        this.$http.delete(appsUrl)
        .then((response) => {
            if (response.data.IsSuccess != true) {
                this.tempMessage = response.data.Message
            }
            location.href = ''
        })
      }
  },
  mounted: function () {
      this.workList = JSON.parse(localStorage.getItem('workList'))
      this.locationId = this.workList[0].locationId
      this.quantity = this.workList[0].qtyRequested
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>