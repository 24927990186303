<template>
  <v-tabs
      bg-color="primary"
  >
    <v-tab href="#one">Users</v-tab>
    <v-tab href="#two">Roles</v-tab>
    <v-tab href="#three">Permissions</v-tab>
    <v-tab-item value="one">
      <Users/>
    </v-tab-item>
    <v-tab-item value="two">
      <Roles/>
    </v-tab-item>
    <v-tab-item value="three">
      <RolesPermissions/>
    </v-tab-item>
  </v-tabs>
</template>

<script>

import Users from "@/components/WarehouseConfiguration/Users";
import Roles from "@/components/WarehouseConfiguration/Roles";
import RolesPermissions from "@/components/WarehouseConfiguration/RolesPermissions";

export default {
  name: "UsersRolesOverview.vue",
  components: {RolesPermissions, Roles, Users},
  data: () => ({
    tabon: "one"
  })
}
</script>

<style scoped>

</style>