<template>
  <v-toolbar
      color="grey"
      dark
      dense
      flat
  >
    <v-toolbar-title class="text-md-body-1">
      {{ title }}
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn
        small
        @click="onBack"
        color="#EEEEEE"
        class="ma-2 black--text"
    >
      <v-icon
          left
      >
        mdi-arrow-left
      </v-icon>
      Back
    </v-btn>
    <v-btn
        small
        @click="onSave"
        color="grey-darken-3"
        class="ma-2 white--text"
        :disabled="disabled"
    >
      <v-icon
          left
          dark
      >
        mdi-check
      </v-icon>
      Save
    </v-btn>
  </v-toolbar>
</template>

<script>
export default {
  name: "CreateEditToolbar",
  props: {
    title: "",
    disabled: false,
    onSave: Function,
    onBack: Function,
  },
};
</script>

<style scoped>

</style>