<template>
    <div class="mt-3">
      <v-alert
          v-show="isAlertVisible"
      >
        {{ tempMessage }}
      </v-alert>
      <v-toolbar
          color="grey"
          dark
          dense
          flat
      >
        Dropoff Container {{deliveryContainer.licensePlateNumber}} at {{deliveryContainer.dropOffLocation}}
      </v-toolbar>
      <v-divider>

      </v-divider>
      <v-btn-toggle>
        <v-btn
          v-on:click="dropOffDeliveryContainer()"
          dark
          color="grey-darken-3"
          dense
        >
          Confirm Dropoff
        </v-btn>
      </v-btn-toggle>
    </div>
</template>

<script>
export default {
    name: 'DropOffDeliveryContainer',
    props: {
        msg: String
    },
    data() {
        return {
            userId: 'test1',
            zoneId: 'zone1',
            containerId: '',
            currentState: '',
            tempMessage: '',
            deliveryContainer: '',
          isAlertVisible: false,
        }
    },
    methods: {
        dropOffDeliveryContainer: async function() {
          const deliveryContainerId = this.deliveryContainer.licensePlateNumber;
          const appsUrl = 'http://' + this.$host + ':' + this.$receivingPort + '/api/receiving/ReceivingContainer/' +
            deliveryContainerId + '/dropOff';

          try {
            const response = await this.$http.put(appsUrl);
            location.href = '#/delivery/inductDeliveryContainer';
          } catch (error) {
            if (error.response) {
              console.error(error.response.data);
            } else if (error.request) {
              console.error(error.request);
            } else {
              console.error('Error: ', error.message);
            }
          }
        },
        getDeliveryContainer: async function(deliveryContainerId) {
            const appsUrl = 'http://' + this.$host + ':' + this.$receivingPort + '/api/receiving/ReceivingContainer/' +
              deliveryContainerId;
            try {
              const response = await this.$http.get(appsUrl);
              this.deliveryContainer = response.data;
            } catch (error) {
              if (error.response) {
                console.error(error.response.data);
                this.tempMessage = 'Need to identify a container.';
              } else if (error.request) {
                console.error(error.request);
              } else {
                console.error('An error occurred while getting the delivery container: ', error.message);
              }
            }
        }
    },
    mounted: function() {
        const deliveryContainerId = this.$route.params.deliveryContainerId;
        this.getDeliveryContainer(deliveryContainerId);
    }
}
</script>