<template>
    <v-stepper v-model="e1">
      <v-stepper-header>
        <v-stepper-step
            :complete="e1 > 1"
            step="1"
        >
          Activation
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step
            :complete="e1 > 2"
            step="2"
        >
          Account Creation
        </v-stepper-step>

        <v-divider></v-divider>

        <v-stepper-step step="3">
          Feature Selection
        </v-stepper-step>
      </v-stepper-header>

      <v-stepper-items>
        <v-stepper-content step="1">
          <AccountActivation/>
          <v-btn
              color="primary"
              @click="e1 = 2"
          >
            Continue
          </v-btn>

          <v-btn text>
            Cancel
          </v-btn>
        </v-stepper-content>

        <v-stepper-content step="2">
          <AccountCreation/>
          <v-btn text
              @click="e1 = 1"
          >
            < Back
          </v-btn>
          <v-btn
              color="primary"
              @click="e1 = 3"
          >
            Continue
          </v-btn>

          <v-btn text>
            Cancel
          </v-btn>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-card
              class="mb-12"
              color="grey lighten-1"
              height="200px"
          ></v-card>
          <v-btn
              @click="e1 = 2"
          >
            < Back
          </v-btn>
          <v-btn
              color="primary"
              @click="e1 = 1"
          >
            Continue
          </v-btn>

          <v-btn text>
            Cancel
          </v-btn>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
</template>

<script>

import {azureLogin} from "@/js/users";
import AccountActivation from "@/components/Accounts/AccountActivation.vue";
import AccountCreation from "@/components/Accounts/AccountCreation.vue";

export default {
  name: 'CreateAccountWizard',
  components: {AccountCreation, AccountActivation},
  props: {
    msg: String,
    e1: 0
  },
  data() {
    return {
      activationKey: '',
      step: 0
    }
  },
  methods: {
    isSubmitButtonDisabled() {
      if (this.activationKey == '' || this.activationKey.length < 1) {
        return true;
      }

      return false;
    },
    async onSignup() {
      await this.$router.push('accounts/createAccount');
    },
    async onLogin() {
      let isAzureAdLogin = false;
      if (isAzureAdLogin) {
        await azureLogin();
      }
      else {
        let response = null;
        if (response != null && response !== "") {
          this.alertMessage = response;
          this.alertVisible = true;
        } else {
          this.alertMessage = "";
          this.alertVisible = false;
          await this.$router.push('/warehouseOverview');
        }
      }
    },
  },
  mounted: function () {
  }
}
</script>