<template>
  <div>
      <v-alert
        v-show="isAlertVisible"
        :type="variant"
        dismissible>
        <p>{{ tempMessage }}</p>
      </v-alert>
      <div>
          <v-toolbar
            color="grey"
            dark
            dense
            flat
          >
            Identify container
          </v-toolbar>
          <v-text-field
              v-model="containerId"
              placeholder="Scan or enter container LPN."
              v-on:keyup.enter="confirm()"
              autofocus
          />
        <v-btn-toggle>
          <v-btn
              v-on:click="confirm()"
              :dark="!isConfirmDisabled()"
              :disabled="isConfirmDisabled()"
          >
            Confirm
          </v-btn>
        </v-btn-toggle>
      </div>
  </div>
</template>
<script>
export default {
    name: 'InductDeliveryContainer',
    props: {
        msg: String
    },
    data() {
      return {
        userId: 'test1',
        zoneId: 'zone1',
        containerId: '',
        currentState: '',
        delivery: '',
        dismissCountDown: 0,
        dismissSeconds: 10,
        variant: "success",
        tempMessage: "Test",
        isAlertVisible: false,
      }
    },
    methods: {
        countDownChanged: function(dismissCountDown) {
            this.dismissCountDown = dismissCountDown;
        },
      isConfirmDisabled: function() {
        if (this.containerId == '') {
          return true;
        }

          return false;
      },
        confirm: async function() {
            if (this.containerId === '') {
                this.tempMessage = 'Please identify a container.';
                this.variant = "warning";
                this.dismissCountDown = 10;
                return;
            }

            const appsUrl = 'http://' + this.$host + ':' + this.$receivingPort + '/api/receiving/ReceivingContainer/' +
                this.containerId + '/induct';

            try {
              const response = await this.$http.put(appsUrl);
              this.delivery = response.data;
              location.href = '#/delivery/confirmDeliveryContainer/' + this.delivery.licensePlateNumber;
            } catch (exception) {
              if (exception.response) {
                this.tempMessage = 'Please identify a container.';
              } else if (exception.request) {
                this.tempMessage = 'An error occurred';
                console.error('An error occurred while inducting the delivery container: ', exception.request);
              } else {
                this.tempMessage = 'An error occurred';
                console.error('An error occurred while inducting the delivery container: ', exception.message);
              }
            }
        },
    },  
}
</script>