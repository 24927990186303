<template>
  <div>
    <v-alert
        v-model="alert"
        dismissible
        type="error"
        border="left"
        elevation="2"
        colored-border
    >
      {{ alertMessage }}
    </v-alert>
    <v-card>
      <CreateEditToolbar
          :title="title + deliveryId"
          :on-back="goBack"
          :on-save="createDelivery"
          :disabled="!isEditable"
      />
      <v-container
        fluid
      >
        <v-row>
          <v-col
            cols="7"
          >
            <v-card>
              <v-card-title>Summary</v-card-title>
              <v-card-text>
                <v-text-field
                    label="Delivery"
                    v-model="deliveryId"
                    :rules="nameRules"
                    :disabled="!isEditable"
                    required
                    outlined
                    dense
                    autofocus
                />
              </v-card-text>
            </v-card>
          </v-col>
          <v-col
            cols="5"
          >
            <v-card
            >
              <v-card-title>Status</v-card-title>
              <v-stepper>
                <v-stepper-header>
                  <v-stepper-step step="1" :complete="delivery.state >= 0">Created</v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step step="2" :complete="delivery.state >= 1">Arrived</v-stepper-step>
                  <v-divider></v-divider>
                  <v-stepper-step step="4" :complete="delivery.state > 3">Completed</v-stepper-step>
                </v-stepper-header>
              </v-stepper>
            </v-card>
          </v-col>
        </v-row>
        <v-row
        >
          <v-col>
            <v-card>
              <v-card-title>
                Containers
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-text-field
                      label="LPN"
                      v-model="lpn"
                      :disabled="!isEditable"
                      :items="skus"
                      required
                      outlined
                      dense
                  />
                  <v-btn
                      :disabled="!isEditable || lpn == null || lpn === ''"
                      @click="addContainer()"
                  >
                    Add Container
                  </v-btn>
                </v-row>
                <v-row>
                  <v-data-table
                      :items="containers"
                      :headers="deliveryContainerHeaders"
                      :single-select="true"
                      item-key="licensePlateNumber"
                      show-select
                      loading-text="Loading... Please wait"
                      dense
                      onselect="onSelectDeliveryContainer(selectedDeliveryContainers[0])"
                      v-model="selectedDeliveryContainers"
                  >
                    <template v-slot:item.actions="{ item }">
                      <v-icon
                          small
                          @click="deleteContainer(item)"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                    <template
                        v-slot:item.licensePlateNumber="{ item }"
                    >
                      <v-row v-on:click="onSelectDeliveryContainer(item)">
                        {{ item.licensePlateNumber }}
                      </v-row>
                    </template>
                    <template v-slot:item.state="{ item }">
                      <v-chip
                          :color="getStateColor(item.state)"
                          dark
                          small
                      >
                        {{ formatState(item.state) }}
                      </v-chip>
                    </template>
                  </v-data-table>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
          <v-col>
            <v-card>
              <v-card-title>
                Items
              </v-card-title>
              <v-card-text>
                <v-row>
                  <v-autocomplete
                      label="SKU / Product"
                      v-model="itemSkuId"
                      :rules="itemSkuIdRules"
                      :disabled="!isEditable"
                      :items="skus"
                      required
                      outlined
                      dense
                  />
                  <v-text-field
                      label="Quantity"
                      v-model="itemQuantity"
                      :rules="itemQuantityRules"
                      :disabled="!isEditable"
                      required
                      outlined
                      dense
                  />
                  <v-btn
                      :disabled="!isEditable || itemSkuId == null || itemQuantity < 1 || selectedDeliveryContainers.length < 1"
                      @click="addItem"
                  >
                    Add Item
                  </v-btn>
                </v-row>
                <v-row>
                  <v-data-table
                    :items="selectedDeliveryContainerItems"
                    :headers="deliveryItemHeaders"
                    loading-text="Loading... Please wait"
                    dense
                  >
                    <template v-slot:item.actions="{ item }">
                      <v-icon
                          small
                          @click="deleteItem(item)"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                  </v-data-table>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>
<style>
</style>
<script>

import CreateEditToolbar from "@/components/Base/CreateEditToolbar.vue";
import axios from "axios";
import * as delivery from "@/js/delivery";

export default {
  name: 'CreateEditDelivery',
  components: { CreateEditToolbar },
  computed: {
    getSelectedDeliveryContainerItems() {
      if (this.selectedDeliveryContainers && this.selectedDeliveryContainers[0] && this.selectedDeliveryContainers[0].items) {
        console.log('Returning the list of items');
        return this.selectedDeliveryContainers[0].items;
      }

      console.log('Returning an empty list');
      return [];
    },
  },
  data () {
    return {
      deliveryId: '',
      isEditable: true,
      itemSkuId: '',
      itemQuantity: 0,
      delivery: {},
      deliveryItems: [],
      deliveryContainerItems: [],
      skus: [],
      arrivalDate: '',
      arrivalTime: '',
      lpn: '',
      containers: [],
      selectedDeliveryContainers: [],
      selectedDeliveryContainerItems: [],
      deliveryContainerHeaders: [
        { text: 'Actions', value: 'actions', sortable: false },
        { text: 'Container', value: 'licensePlateNumber' },
        { text: 'Status', value: 'state' },
      ],
      deliveryItemHeaders: [
        { value: 'actions', text: 'Actions', sortable: false },
        { text: "SKU", value: "skuId" },
        { text: "Expected Quantity", value: "quantityToReceive" },
        { text: "Received Quantity", value: "quantityActual" },
        { text: "Container", value: "licensePlateNumber" }
      ],
      title: 'Delivery Details - ',
      nameRules: [
        v => !!v || 'Delivery is required',
      ],
      itemQuantityRules: [
          v => v > 0 || 'Quantity must be greater than 0'
      ],
      itemSkuIdRules: [
          v => v.length > 0 || 'SKU is required'
      ],
      alert: false,
      alertMessage: '',
    }
  },
  methods: {
    createDelivery: async function () {
      let deliveryDto = {};
      deliveryDto.taskId = this.deliveryId;
      deliveryDto.containers = this.containers;
      deliveryDto.items = this.deliveryItems;
      deliveryDto.arrivalDate = this.arrivalDate + "T" + this.arrivalTime;
      if (!this.arrivalDate || this.arrivalDate === '' || this.arrivalTime === '') {
        let currentDate = new Date();
        deliveryDto.arrivalDate = currentDate.toISOString();
      }

      const appsUrl = 'http://' + this.$host + ':' + this.$receivingPort + '/api/receiving/ReceivingTask';
      try {
        await axios.post(appsUrl, deliveryDto);
      } catch (e) {
        this.alert = true;
        this.alertMessage = e.response.data;
        return;
      }

      location.href = '#/delivery';
    },
    onSelectDeliveryContainer: function(item) {
      console.log('Selecting: ', item);
      this.selectedDeliveryContainers = [];
      this.selectedDeliveryContainers.push(item);
      this.selectedDeliveryContainerItems = this.selectedDeliveryContainers[0].items;
      console.log("Container items: ", this.selectedDeliveryContainerItems);
    },
    formatState: function(state) {
      return delivery.formatDeliveryContainerState(state);
    },
    addItem: async function () {
      let newItem = {};
      newItem.skuId = this.itemSkuId;
      newItem.quantityToReceive = this.itemQuantity;
      if (!this.selectedDeliveryContainerItems) {
        this.selectedDeliveryContainerItems = [];
      }

      if (!this.selectedDeliveryContainers[0].items) {
        this.selectedDeliveryContainers[0].items = [];
      }

      this.selectedDeliveryContainers[0].items.push(newItem);
      this.selectedDeliveryContainerItems.push(newItem);
    },
    addContainer: async function() {
      let newContainer = {};
      newContainer.licensePlateNumber = this.lpn;
      this.containers.push(newContainer);
      this.lpn = '';
      this.resetSelectedDeliveryContainers(newContainer);
    },
    goBack() {
        location.href = '#/delivery'
    },
    getStateColor: function(state) {
      if (state === 0) {
        return 'blue';
      } else if (state === 1) {
        return 'teal';
      }

      return 'green';
    },
    resetSelectedDeliveryContainers() {
      this.selectedDeliveryContainers = [];
    },
    deleteItem(item) {
      this.deliveryItems.remove(item.id);
    },
    deleteContainer(item) {
      this.containers.splice(this.containers.indexOf(item.id), 1);
    },
    getDeliveryItems: async function(deliveryId) {
      if (deliveryId == null || deliveryId === '') {
        this.isEditable = true;
        return;
      }

      this.isEditable = false;
      const receivingTaskUrl = 'http://' + this.$host + ':' + this.$receivingPort + '/api/receiving/ReceivingTask/' + this.deliveryId;
      try {
        let response = await axios.get(receivingTaskUrl);
        if (response.status < 200 || response.status > 299) {
          this.alertMessage = 'An error occurred';
          this.alert = true;
        }

        this.delivery = response.data;
        this.deliveryItems = this.delivery.items;
        this.containers = this.delivery.containers;
        if (this.deliveryItems != null && this.deliveryItems.length > 0) {
          return;
        }

        for (let i = 0; i < this.delivery.containers.length; i++) {
          for (let j = 0; j < this.delivery.containers[i].items.length; j++) {
            this.deliveryItems.push(this.delivery.containers[i].items[j]);
          }
        }
      } catch (e) {
        this.alert = true;
        this.alertMessage = e;
      }
    },
  },
  mounted: async function() {
    this.deliveryId = this.$route.params.deliveryId;
    await this.getDeliveryItems(this.deliveryId);
    const appsUrl = 'http://' + this.$host + ':' + this.$inventoryPort + '/api/inventory-management/sku/';
    let skuResponse = await axios.get(appsUrl);
    if (skuResponse.status < 200 || skuResponse.status > 299) {
      this.alertMessage = 'An error occurred fetching inventory';
      this.alert = true;
    }

    for (let i = 0; i < skuResponse.data.length; i++) {
      this.skus.push(skuResponse.data[i].skuId);
    }
  }
}
</script>
<style>
</style>