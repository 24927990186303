<template>
  <div>
    <v-card>
      <v-toolbar
          color="grey"
          dark
          dense
          flat
      >
        <v-toolbar-title class="text-md-body-1">
          {{ title }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn
            small
            @click="create"
            color="grey-darken-3"
            class="ma-2 white--text"
        >
          <v-icon
              left
              dark
          >
            mdi-pencil
          </v-icon>
          Create</v-btn>
      </v-toolbar>
      <v-card-title
          class="text-h6"
      >
      </v-card-title>

      <v-data-table
          :items="purchaseOrders"
          :headers="headerFields"
          :search="search"
          loading="isLoading"
          loading-text="Loading... Please wait"
          dense
          class="elevation-1">
        <template
            v-slot:item.state="{ item }"
        >
          {{ formatState(item.state) }}
        </template>
        <template #item.orderNumber="{ item }">
          <router-link :to="{ name: 'CreatePurchaseOrder', params: { orderNumber: item.orderNumber } }">
            {{ item.orderNumber }}
          </router-link>
        </template>
        <template v-slot:item.dateCreated="{ item }">
          <v-row>
            {{ configurations.formatDate(item.dateCreated) }}
          </v-row>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import * as inventory from "../../../js/inventory.js";
import * as configurations from "../../../js/configurations.js"

export default {
  name: 'PurchaseOrder',
  computed: {
    configurations() {
      return configurations
    }
  },
  props: {
    msg: String
  },
  data() {
    return {
      isLoading: true,
      search: '',
      title: 'Purchase Orders',
      purchaseOrders: [],
      headerFields: [
        { value: "orderNumber", text: "Order Number" },
        { value: "dateCreated", text: 'Date Created',
          formatter: (value, key, item) => {
            return configurations.formatDate(value);
          }
        },
        { value: "deliveryDate", text: "Anticipated Delivery Date"},
        {
          value: "status", text: 'Status',
          formatter: (value, key, item) => {
            return this.formatState(value);
          }
        }
      ],
      breadcrumbs: [
        { text: "Home", href: "/" },
        { text: "Inventory", href: "/" },
        { text: "Purchase Orders" }
      ],
      totalRows: 0,
      currentPage: 1,
      rowsPerPage: 20,
      isBusy: false,
      productImage: "",
    }
  },
  methods: {
    formatState: function(state) {
      return inventory.getStatus(state);
    },
    create: function() {
      location.href = '#/inventory/createPurchaseOrder';
    },
    getHyperLink: function() {
      return '#/inventory/createPurchaseOrder/item.orderNumber';
    }
  },
  mounted: async function() {
    this.purchaseOrders = await inventory.getPurchaseOrders();
    this.isLoading = false;
  }
}
</script>