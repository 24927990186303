<template>
  <div class="induct">
    <v-alert 
        :show="dismissCountDown"
        :variant="variant"
        dismissible
        @dismissed="dismissCountDown==0" 
        @dismiss-count-down="countDownChanged">
        <p>{{ tempMessage }}</p>
    </v-alert>
    <h2>Putaway Cart {{ cartId }}</h2>
    <h3>Go to location {{ currentPutaway.locationId }}</h3>
    <v-btn v-on:click="confirm()" v-on:keyup.enter="confirm()">Confirm</v-btn>
    <v-btn v-on:click="addPutaway()">Add Putaway</v-btn>
  </div>
</template>
<script>
export default {
    name: 'InductPutawayCart',
    props: {
        msg: String
    },
    data() {
        return {
            userId: 'test1',
            zoneId: 'zone1',
            containerId: '',
            currentState: '',
            delivery: '',
            dismissCountDown: 0,
            dismissSeconds: 10,
            variant: "success",
            cartId: '',
            putawayCart: [],
            tempMessage: "",
            currentPutaway: Object(),
            remainingPutaways: [],
        }
    },
    methods: {
        countDownChanged: function(dismissCountDown) {
            this.dismissCountDown = dismissCountDown;
        },
        confirm: function() {
            this.sendPutawayConfirmation();
        },
        sendPutawayConfirmation: function() {
            var putawayId = this.currentPutaway.putawayId;
            const appsUrl = 'http://' + this.$host + ':' + this.$putawayPort + '/api/putaway/Putaway/' + putawayId + '/confirm';
            this.$http.post(appsUrl)
                .then((response) => {
                    if (this.remainingPutaways.length > 0) {
                        this.currentPutaway = this.remainingPutaways.pop();
                    } else {
                        var url = '#/putaway/cart/' + this.cartId + '/addPutawayToCart';
                        location.href = url;
                    }
                })
                .catch((error) => {
                    this.tempMessage = error.data;
                });
        },
        inductCart: function() {
            const appsUrl = 'http://' + this.$host + ':' + this.$putawayPort + '/api/putaway/Putaway/cart/' + this.cartId + '/induct';
            this.$http.post(appsUrl)
                .then((response) => {
                    this.putawayCart = response.data;
                    this.remainingPutaways = this.putawayCart.putaways.filter(this.isPutawayValid);
                    if (this.remainingPutaways.length > 0) {
                        this.currentPutaway = this.remainingPutaways.pop();
                    } else {
                        this.addPutaway();
                    }
                })
                .catch(function (error) {
                    if (error.response) {
                        this.tempMessage = 'Please identify a container.';
                    } else if (error.request) {
                        console.error(error.request);
                    } else {
                        console.error('Error', error.message);
                    }
                });
        },
        isPutawayValid: function(putaway) {
            if (putaway.status != 5) {
                return true;
            }

            return false;
        },
        addPutaway: function() {
            var url = '#/putaway/cart/' + this.cartId + '/addPutawayToCart';
            location.href = url;
        }
    },
    mounted() {
        this.cartId = this.$route.params.cartId;
        this.inductCart();
    }  
}
</script>