<template>
  <div>
    <h2>Users</h2>
    <v-data-table
        :items="users"
        :headers="userHeaders"
    >
      <template v-slot:item.roles="{ item }">
        <v-chip-group v-for:item.roles>

          <v-chip v-for="role in item.roles">
            {{ role.name }}
          </v-chip>
        </v-chip-group>
      </template>

    </v-data-table>
  </div>
</template>

<script>

export default {
  name: 'Users.vue',
  props: {
    msg: String
  },
  components: {
  },
  data() {
    return {
      users: [],
      userHeaders: [
        { text: 'User', value: 'userName' },
        { text: "Full Name", value: "fullName" },
        { text: "Email", value: "email"},
        { text: "Roles", value: "roles" }
      ]
    }
  },
  methods: {
  },
  mounted: function () {
    const appsUrl = 'http://' + this.$host + ':' + this.$userManagementPort + '/api/user-management/user';
    this.$http.get(appsUrl)
        .then((result) => {
          this.users = result.data;
        });
  }
}
</script>