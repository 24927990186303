<template>
  <div class="induct">
    <v-alert 
        :show="dismissCountDown"
        :variant="variant"
        dismissible
        @dismissed="dismissCountDown==0" 
        @dismiss-count-down="countDownChanged"
        v-show="isAlertVisible"
    >
        <p>{{ tempMessage }}</p>
    </v-alert>
    <h2>Identify Putaway for {{ cartId }}</h2>
    <v-text-field
        v-model="containerId"
        placeholder="Scan or enter container LPN."
        v-on:keyup.enter="confirm()"
        autofocus
    />
    <v-btn v-on:click="confirm()">Confirm</v-btn>
    <v-btn v-on:click="induct()">Induct Cart</v-btn>
  </div>
</template>
<script>
export default {
    name: 'AddPutawayToCart',
    props: {
        msg: String
    },
    data() {
        return {
            userId: 'test1',
            zoneId: 'zone1',
            containerId: '',
            currentState: '',
            delivery: '',
            dismissCountDown: 0,
            dismissSeconds: 10,
            variant: "success",
            tempMessage: "",
            cartId: '',
            isAlertVisible: false
        }
    },
    methods: {
        countDownChanged: function(dismissCountDown) {
            this.dismissCountDown = dismissCountDown;
        },
        confirm: function() {
            if (this.containerId == '') {
                this.tempMessage = 'Please identify a container.';
                this.variant = "warning";
                this.dismissCountDown = 5;
                return;
            }

            const appsUrl = 'http://' + this.$host + ':' + this.$putawayPort + '/api/putaway/Putaway/cart/' + this.cartId + '/addContainer/' + this.containerId;
            this.$http.post(appsUrl)
                .then((response) => {
                    this.delivery = response.data;
                    this.containerId = '';
                    this.tempMessage = 'Putaway added to cart.';
                    this.variant = "success";
                    this.dismissCountDown = 5;
                    return;
                })
                .catch((error) => {
                    this.handleError(error);
                });
        },
        induct: function() {
            const url = '#/putaway/putaway/cart/' + this.cartId + '/induct';
            location.href = url;
        },
        handleError: function(error) {
            this.tempMessage = error.response.data;
            this.variant = 'warning';
            this.dismissCountDown = 5;
            this.containerId = '';
        }
    },
    mounted() {
        this.cartId = this.$route.params.cartId;
    }
}
</script>