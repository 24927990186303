<template>
  <v-container fluid class="grey lighten-5" height="100%">
    <v-row>
      <Toolbar
        :title="title"
        :on-back="back"
        :on-save="save"
      />
      <PurchaseOrderHeader :vendors="vendors"/>
      <PurchaseOrderDetails/>
      <PurchaseOrderItems :purchase-order-items="purchaseOrderItems"/>
      <PurchaseOrderFooter/>
    </v-row>
  </v-container>
</template>
<style>
</style>
<script>
import vendors from '../../../data/vendors.json';
import PurchaseOrderHeader from "@/components/inventory/PurchaseOrders/PurchaseOrderHeader.vue";
import PurchaseOrderDetails from "@/components/inventory/PurchaseOrders/PurchaseOrderDetails.vue";
import Toolbar from "@/components/Base/OverviewToolbar.vue";
import PurchaseOrderItems from "@/components/inventory/PurchaseOrders/PurchaseOrderItems.vue";
import PurchaseOrderFooter from "@/components/inventory/PurchaseOrders/PurchaseOrderFooter.vue";

export default {

  name: 'CreatePurchaseOrder',
  components: {PurchaseOrderFooter, PurchaseOrderItems, Toolbar, PurchaseOrderDetails, PurchaseOrderHeader},
  data () {
    return {
      orderNumber: '',
      deliveryDate: '',
      notes: '',
      title: 'Create Purchase Order',
      purchaseOrder: {},
      purchaseOrderItems: [],
      alert: false,
      alertText: '',
      vendors: vendors,
    }
  },
  methods: {
    save: function() {
      /**
       * {
       *   "dateCreated": "2023-11-22T23:19:08.438Z",
       *   "orderNumber": "ORD0001234",
       *   "contactName": "string",
       *   "department": "string",
       *   "phoneNumber": "string",
       *   "discountPercent": 0,
       *   "taxRate": 0,
       *   "shippingHandlingFee": 0,
       *   "notes": "string"
       * }
       * @type {Object}
       */

      this.purchaseOrder.dateCreated = new Date(Date.now()).toISOString();
      this.purchaseOrder.department = "Warehouse Inventory";
      this.purchaseOrder.phoneNumber = "616-654-0912";
      this.purchaseOrder.discountPercent = 0.1;
      this.purchaseOrder.taxRate = 0.06;
      this.purchaseOrder.shippingHandlingFee = 2.15;
      this.purchaseOrder.notes = this.notes;
      this.purchaseOrder.items = []
      const appsUrl = 'http://' + this.$host + ':' + this.$inventoryPort + '/api/inventory-management/PurchaseOrder';
      this.$http.post(appsUrl, this.purchaseOrder)
          .then((result) => {
          });

      location.href = '#/inventory/purchaseOrders';
    },
    back: function() {
      location.href = '#/inventory/purchaseOrders';
    },
  },
  mounted: async function() {

  }
}
</script>
<style>
</style>