import axios from 'axios';
import Vue from 'vue'
import jwt_decode from "jwt-decode";
import * as msal from "@azure/msal-browser";

export function isUserLoggedIn() {
    let userToken = getUserToken();
    if (userToken && userToken != null) {
        return true;
    } else {
        return false;
    }
}

export function getUserToken() {
    let userToken = localStorage.getItem('user');
    return userToken;
}

export function decodeUserToken() {
    let userToken = getUserToken();
    let userTokenDecoded = jwt_decode(userToken);
    return userTokenDecoded;
}

export async function azureLogin() {
    const msalConfig = {
        auth: {
            clientId: '552b3120-5d56-4c15-be40-c86714720385',
            authority: 'https://login.microsoftonline.com/jasontierney.onmicrosoft.com/',
            redirectUri: "http://localhost:8080"
        }
    };

    const msalInstance = new msal.PublicClientApplication(msalConfig);
    await msalInstance.initialize();

    // Login the user
    let loginResponse;
    try {
        loginResponse = await msalInstance.ssoSilent({});
    } catch (err) {
        console.error("An error occurred while logging in: ", err);
    }

    var request = {
        scopes: ["User.Read"],
    };

    let tokenResponse;
    try {
        tokenResponse = await msalInstance.acquireTokenPopup(request);
    } catch (err) {
        console.error("An error occurred while acquiring the token: ", err);
    }

    // Get details about me
    var headers = new Headers();
    var bearer = "Bearer " + tokenResponse.accessToken;
    headers.append("Authorization", bearer);
    var options = {
        method: "GET",
        headers: headers
    };

    // GET https://graph.microsoft.com/v1.0/users/6e7b768e-07e2-4810-8459-485f84f8f204/memberOf
    let userId = loginResponse.account.localAccountId;
    var graphEndpoint = "https://graph.microsoft.com/v1.0/users/" + userId + "/memberOf";

    fetch(graphEndpoint, options)
        .then(resp => {
            return resp.json();
        }).then(function(data) {
    });
}

export async function getUserAzureToken() {
// Auth the user

}

export function getUserName() {
    let userTokenDecoded = decodeUserToken();
    let userName = userTokenDecoded.name;
    return userName;
}

export function getUserRoles() {
    let userToken
}

export function getUserDarkModePreference() {
    return JSON.parse(localStorage.getItem("dark-mode-preference"));
}

export function setUserNavPreference(preference) {
    localStorage.removeItem("show-nav");
    localStorage.setItem("show-nav", preference);
}

export function getUserNavPreference() {
    return JSON.parse(localStorage.getItem("show-nav"));
}

export function setUserDarkModePreference(value) {
    localStorage.setItem("dark-mode-preference", value);
}

export async function userLogin(username, password) {
    let encryptedPassword = encryptPassword(password);
    let user = {
        username: username,
        password: encryptedPassword
    }

    const appsUrl = 'http://' + Vue.prototype.$host + ':' + Vue.prototype.$userManagementPort + '/api/user-management/User/authenticate';
    try {
        let response = await axios.post(appsUrl, user);
        if (response) {
            let userToken = response.data;
            localStorage.setItem('user', userToken);
        }
    } catch(error) {
        if (error.response) {
            if (error.response.status == 401) {
                return 'Invalid username or password.';
            }
        } else if (error.result) {
            console.error(error.request);
            return error.message;
        } else {
            console.error('Error', error.message);
            return error.message;
        }
    }
}

export function encryptPassword(password) {
    let encryption = require("crypto-js");
    let key = encryption.enc.Utf8.parse('8080808080808080');
    let iv = encryption.enc.Utf8.parse('8080808080808080');
    let cipherText = encryption.AES.encrypt(encryption.enc.Utf8.parse(password), key,
        {
            keySize: 128 / 8,
            iv: iv,
            mode: encryption.mode.CBC,
            padding: encryption.pad.Pkcs7
        });

    return cipherText.toString();
}