<template>
  <v-container>
    <h1>Report Feedback</h1>
    <v-text-area>

    </v-text-area>
  </v-container>
</template>

<script>
export default {
  name: "ReportFeedback"
}
</script>

<style scoped>

</style>