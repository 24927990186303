<template>
  <div class="container">
    <b-card id="content"
            :border-variant=severity
            :header-border-variant=severity
            :header-text-variant=headerColor
            :header=title
            :header-bg-variant=severity
            >
    </b-card>
  </div>
</template>
<style>
</style>
<script>
export default {
  name: 'OrderWidget',
  props: {
    title: String,
    content: String,
    severity: String,
  },
  data () {
    return {
      headerColor: 'black'
    }
  },
  methods: {
    
  },
  mounted: function () {
    if (this.severity === 'danger') {
      this.headerColor = 'white';
    }
  }
}
</script>
<style>
</style>