<template>
  <div class="orders_table" width="900">
    <h3>Warehouse Overview</h3>
    <canvas id="mainCanvas" v-on:click="canvasClickHandler" width="650" height=375></canvas>
  </div>
</template>
<script>

export default {
  name: 'Demo01',
  props: {
    msg: String
  },
  components: {
  },
  data() {
      return {
          operators: [],
          bays: [],
          colors: ['red', 'green', 'blue', 'cyan', 'yellow', 'fuchsia', 'orange', 'crimson', 'black', 'chartreuse']
      }
  },
  methods: {
      getOperators: function() {
        const appsUrl = 'http://' + this.$host + ':' + this.$pickingPort + '/api/picking/User/GetUserLocations';
            this.$http.get(appsUrl)
                .then((result) => {
                    this.operators = result.data;
                    this.drawCanvas();
                })
        },

      drawCanvas: function() {
        if (this.bays == null || this.bays.length < 1) {
            this.createBays();
        }

        var mainCanvas = document.getElementById("mainCanvas");

        var ctx = mainCanvas.getContext("2d");
        ctx.clearRect(0, 0, mainCanvas.width, mainCanvas.height);

        ctx.strokeRect(0, 0, 650, 350);
        ctx.fillStyle = 'black';
        ctx.fillText("Picking", 0, 10);
        for (var i = 0; i < this.bays.length; i++) {
            const bayY = this.bays[i].y;
            const bayX = this.bays[i].x;
            const aisle = this.bays[i].location.substring(1, 3)
            const bay = this.bays[i].location.substring(4, 6)
            for (var opsPos = 0; opsPos < this.operators.length; opsPos++) {
                if (this.operators[opsPos].aisle == aisle && this.operators[opsPos].bay == bay) {
                    var color = this.colors[opsPos % 10]
                    ctx.fillStyle = color
                    ctx.beginPath()
                    ctx.arc(bayX + 38, bayY + 15, 5, 0, 2 * Math.PI)
                    ctx.fill()
                    ctx.stroke()
                }
            }

            ctx.fillStyle = 'black';
            ctx.strokeRect(bayX, bayY, 30, 30);
            ctx.fillText("Bay", bayX + 5, bayY + 15);
            ctx.fillText(bay, bayX + 5, bayY + 25);
        }
      },
      
      createBays: function() {
        this.bays = []
        for (var aisle = 1; aisle < 11; aisle++) {
            for (var bay = 1; bay < 11; bay++) {
                var bayObj = this.createBayObject(aisle, bay);
                this.bays.push(bayObj);
            }
        }
      },

      createBayTitle: function(bayId) {
            var strBay = "B";
            if (bayId < 10) {
                strBay += "0" + bayId;
            } else {
                strBay += bayId;
            }

            return strBay;
      },
      createAisleTitle: function(aisle) {
          var strAisle = "A";
                if (aisle < 10) {
                    strAisle += "0" + aisle;
                } else {
                    strAisle += aisle;
                }
          return strAisle;
      },

      createBayObject(aisle, bay) {
          var strAisle = this.createAisleTitle(aisle);
          var strBay = this.createBayTitle(bay);
          var strLocation = strAisle + strBay;
          const bayObj = {
                    location: strLocation,
                    x: aisle * 60,
                    y: bay * 30
                };
            return bayObj;
      },

      drawOperatorCanvas: function() {
          var operatorCanvas = document.getElementById("operatorCanvas");
          var ctx = operatorCanvas.getContext("2d");
          ctx.clearRect(0, 0, operatorCanvas.width, operatorCanvas.height);

          if (this.operators.length > 0) {
              this.drawOperators(ctx);
          } else {
              // Display message indicating there are no operators.
          }
      },

      drawOperators: function(ctx) {
          for (var i = 0; i < this.operators.length; i++) {
            ctx.font = '12px Arial';
            ctx.fillText(this.operators[i].userId, 5, i * 15);
            var color = this.colors[i % 10];
            ctx.fillStyle = color;
            ctx.fillRect(50, i * 15, 10, 10);
          }
      },

      canvasClickHandler: function(element) {
            var x = element.x;
            var y = element.y;
            for (var i = 0; i < this.bays.length; i++) {
            }
        },
  },
  mounted: function () {
      this.getOperators();
      setInterval(this.getOperators, 30_000);
  }
}
</script>