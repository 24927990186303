<template>
  <div>
    <v-data-table
        v-model="selected"
        :items="configurations"
        :headers="configurationHeaders"
        show-select
    >
      <template v-slot:top>
        <v-toolbar
            flat
        >
          <v-toolbar-title>Configurations</v-toolbar-title>
          <v-divider
              class="mx-4"
              inset
              vertical
          ></v-divider>
          <v-spacer></v-spacer>
          <v-dialog
              v-model="dialog"
              max-width="500px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="primary"
                  dark
                  class="mb-2"
                  v-bind="attrs"
                  v-on="on"
              >
                New Item
              </v-btn>
            </template>
            <v-card>
              <v-card-title>
                <span class="text-h5">{{ editedItem.name }}</span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                        cols="12"
                        sm="6"
                        md="4"
                    >
                      <v-text-field
                          v-model="editedItem.name"
                          label="Name"
                      ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="4"
                    >
                      <v-text-field
                          v-model="editedItem.friendlyName"
                          label="Friendly Name"
                      ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="4"
                    >
                      <v-text-field
                          v-model="editedItem.category"
                          label="Category"
                      ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="4"
                    >
                      <v-text-field
                          v-model="editedItem.description"
                          label="Description"
                      ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="4"
                    >
                      <v-text-field
                          v-model="editedItem.currentValue"
                          label="Current Value"
                      ></v-text-field>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="6"
                        md="4"
                    >
                      <v-text-field
                          v-model="editedItem.defaultValue"
                          label="Default Value"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="close"
                >
                  Cancel
                </v-btn>
                <v-btn
                    color="blue darken-1"
                    text
                    @click="save"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Are you sure you want to delete this item?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon
            small
            class="mr-2"
            @click="editItem(item)"
        >
          mdi-pencil
        </v-icon>
        <v-icon
            small
            @click="deleteItem(item)"
        >
          mdi-delete
        </v-icon>
      </template>

    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'Configurations',
  props: {
    msg: String
  },
  components: {
  },
  data() {
    return {
      configurations: [],
      selected: [],
      editedItem: new Object(),
      editedIndex: 0,
      formTitle: '',
      dialog: false,
      dialogDelete: false,
      configurationHeaders: [
        { text: 'Configuration', value: 'friendlyName' },
        { text: 'Category', value: 'category' },
        { text: 'Description', value: 'description' },
        { text: 'Current Value', value: 'currentValue' },
        { text: 'Default Value', value: 'defaultValue' },
        { text: 'Actions', value: 'actions', sortable: false },
      ]
    }
  },
  methods: {
    editItem(item) {
      this.formTitle = 'Edit';
      this.editedIndex = this.configurations.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true;
    },
    deleteItem() {
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.dialogDelete = false;
    },
    close() {
      this.editedItem = new Object();
      this.dialog = false;
    },
    save() {
      const appsUrl = 'http://' + this.$host + ':' + this.$configurationServicePort + '/api/warehouse-configuration/Configuration';
      this.$http.post(appsUrl, this.editedItem)
          .then((result) => {
            this.refreshData();
            this.dialog = false;
          });
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    refreshData() {
      const appsUrl = 'http://' + this.$host + ':' + this.$configurationServicePort + '/api/warehouse-configuration/Configuration';
      this.$http.get(appsUrl)
          .then((result) => {
            this.configurations = result.data;
          });
    }
  },
  mounted: function () {
    this.refreshData();
  }
}
</script>