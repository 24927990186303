<template>
  <v-main>
    <v-card class="elevation-12">
      <v-toolbar dark color="primary">
        <v-toolbar-title>Activation Code</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-form>
          <v-text-field
              prepend-icon="mdi-account"
              name="activationCodeText"
              label="Activation Code"
              type="text"
              v-model="activationCode"
              autofocus
          ></v-text-field>
          <v-btn text>Don't have an activation code?</v-btn>
        </v-form>
      </v-card-text>
    </v-card>
  </v-main>
</template>

<script>

export default {
  name: 'AccountActivation',
  props: {
    msg: String
  },
  data() {
    return {
      activationCode: '',
      password: '',
      alertMessage: '',
      alertVisible: false
    }
  },
  methods: {
    isSubmitButtonDisabled() {
      if (this.activationCode == '' || this.activationCode.length < 1) {
        return true;
      }

      return false;
    },
  },
  mounted: function () {
  }
}
</script>