<template>
  <div class="scan">
    <div>
        <h5>{{ tempMessage }}</h5>
    </div>
    <div>
        <h4>Scan Container</h4>
        <input v-model="containerId"/>
    </div>
    <table>
        <tr>
            
        </tr>       
        <tr>
            <td>
                <button v-on:click="ready()" v-on:keyup.enter="ready()" class="btn btn-primary">Ready</button>
                <button v-on:click="logout()" class="btn btn-primary">Logout</button>
            </td>
        </tr>            
    </table>
                  
  </div>
</template>

<script>
export default {
  name: 'Scan',
  props: {
    msg: String
  },
  data() {
      return {
          userId: 'test1',
          zoneId: 'zone1',
          containerId: '',
          currentState: '',
          tempMessage: ''
      }
  },
  methods: {
      ready: function() {
          if (this.containerId == '') {
              this.tempMessage = 'Enter a container ID'
              return
          }

          const appsUrl = 'http://' + this.$host + ':' + this.$pickingPort + '/api/v1/Work/WorkList'
          const request = {
              userId: this.userId,
              zoneId: this.zoneId,
              containerId: this.containerId
          }

          this.$http.post(appsUrl, request)
          .then((response) => {
              if (response.data.message != null && response.data.message == 'No work in zone.') {
                this.tempMessage = 'No work in zone.'
                return
              }
              
              if (response.data.isSuccess === false) {
                var errorMessage = response.data.errorMessage;
                this.tempMessage = errorMessage.message;
                return;
              }

              this.$workList = response.data.workList[0].pickList
              var pickOrderId = response.data.workList[0].pickOrderId
              localStorage.setItem('pickOrderId', pickOrderId)
              localStorage.setItem("containerId", this.containerId)
              localStorage.setItem("workList", JSON.stringify(this.$workList))
              if (pickOrderId != null && pickOrderId != "") {
                location.href = '#/rf/pick'
              }
          })
      },
      logout: function () {
        const appsUrl = 'http://' + this.$host + ':' + this.$pickingPort + '/Login/?userName=' + this.userId + '&zoneId=' + this.zoneId
        this.$http.delete(appsUrl)
        .then((response) => {
            if (response.data.IsSuccess !== true) {
                this.tempMessage = response.data.Message;
            }

            location.href = ''
        })
      }
  },
  mounted: function () {
      this.currentState = localStorage.getItem('currentState')
      this.userId = localStorage.getItem('userId')
      if (this.currentState === 'WORKLIST_COMPLETE') {
          let pickOrderId = localStorage.getItem('pickOrderId')
          let pickContainerId = localStorage.getItem('containerId')
          const appsUrl = 'http://' + this.$host + ':' + this.$pickingPort + '/Pick/CompleteWork?pickOrderId=' + pickOrderId + '&pickContainerId=' + pickContainerId + '&userId=' + this.userId
          this.$http.post(appsUrl)
            .then((result) => {
                this.currentState = 'INDUCT'
                localStorage.setItem('currentState', this.currentState)
                this.tempMessage = "Container/Order Complete!"
            })      
      }
  }  
}
</script>