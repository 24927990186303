<template>
  <div>
    <h2>QC Tasks</h2>
    Total QC Tasks: {{ totalRows }}
    <v-data-table class="table table-bordered"
                  :items="items"
                  :headers="orderHeaderFields"
                  head-variant="light"
                  @filtered="onFiltered"
                  @row-clicked="navToOrderDetails">
      <template
          v-slot:item.state="{ item }"
      >
        <v-row>
          {{ formatState(item.state) }}
        </v-row>
      </template>
      <template
          v-slot:item.orderId="{ item }"
      >
        <v-row v-on:click="navToOrderDetails(item.taskId)">
          {{ item.taskId }}
        </v-row>
      </template>
    </v-data-table>
  </div>
</template>
<script>
export default {
  name: 'ViewQcTasks',
  props: {
    msg: String
  },
  data() {
    return {
      items: [],
      orderHeaderFields: [
        { value: 'taskId', text: 'QC Task' },
        { value: 'state', text: 'State' },
        { value: 'licensePlateNumber', text: 'Container LPN' }
      ],
      breadcrumbs: [
        { text: "Home", href: "/" },
        { text: "Operations" },
        { text: "Orders" }
      ],
      currentPage: 1,
      totalRows: 1,
      rowsPerPage: 20,
      filter: null,
    }
  },
  methods: {
    formatDate(value) {
      let date = new Date(value);
      return date.toLocaleString();
    },
    formatState: function(state) {
      return "Created";
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    navToOrderDetails(item, index, event) {
      location.href = '#/orderDetails/' + item;
    }
  },
  mounted: function () {
    const appsUrl = 'http://' + this.$host + ':' + this.$qcPort + '/api/v0.1/QcTask';
    this.$http.get(appsUrl)
        .then((result) => {
          this.items = result.data;
          this.isBusy = false;
          this.totalRows = this.items.length;
        })
        .catch(function(error) {
          console.error('An error occurred while retrieving the QC tasks: ', error.toJSON());
        })
  }
}
</script>