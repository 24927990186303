<template>
  <div>
    <v-card>
      <v-toolbar
          color="grey"
          dark
          dense
          flat
      >
        <v-toolbar-title class="text-md-body-1">
          {{ title }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn
            small
            disabled
            color="grey-darken-3"
            class="ma-2 white--text"
        >
          <v-icon
              left
              dark
          >
            mdi-pencil
          </v-icon>
          Create
        </v-btn>
      </v-toolbar>
      <v-card-title
          class="text-h6"
      >
      </v-card-title>

      <v-data-table
          :items="inventory"
          :headers="headers"
          :search="search"
          no-data-text="No data"
          :loading="isLoading"
          loading-text="Loading... Please wait"
          dense
          class="elevation-1">
        <template v-slot:item.status="{ item }">
          <v-chip
              :color="getStateColor(item.status)"
              dark
              small
          >
            {{ formatState(item.status) }}
          </v-chip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import * as inventory from "../../js/inventory.js";

export default {
  name: 'InventoryLookup',
  props: {
    msg: String
  },
  data() {
    return {
      inventory: [],
      skuId: '',
      headers: [
        { value: "skuId", text: "SKU" },
        { value: "location", text: 'Location' },
        { value: "qtyTotal", text: "Total Quantity" },
        { value: "qtyReserved", text: "Reserved Quantity" },
        {
          value: "status", text: 'Status',
          formatter: (value, key, item) => {
            return this.formatState(value);
          }
        }  
      ],
      breadcrumbs: [
        { text: "Home", href: "/" },
        { text: "Inventory", href: "/" },
        { text: "Inventory Lookup" }
      ],
      totalRows: 0,
      currentPage: 1,
      rowsPerPage: 20,
      isBusy: false,
      productImage: "",
      search: "",
      isLoading: true,
      title: "Inventory Lookup"
    }
  },
  methods: {
      formatState: function(state) {
        console.log("formatting state");
        return inventory.getStatus(state);
      },
      calculateAvailableInventory(inventory) {
        if (inventory == null || inventory === "") {
          return "NaN";
        }

        return inventory.qtyTotal - inventory.qtyReserved;
      },
      fetchInventoryForSku: function() {
        if (this.skuId === '') {
          this.productImage = "image-placeholder.png";
          return;
        }

        const appsUrl = 'http://' + this.$host + ':' + this.$inventoryPort + '/api/inventory-management/Inventory/';
        this.$http.get(appsUrl)
          .then((result) => {
            this.inventory = result.data;
            this.isLoading = false;
          });
      },
    getStateColor: function(state) {
      if (state === 0) {
        return 'blue';
      } else if (state === 1) {
        return 'teal';
      }

      return 'green';
    },
  },
  mounted() {
    this.skuId = this.$route.params.skuId;
    this.productImage = "image-placeholder.png";

    this.fetchInventoryForSku();
  }
}
</script>