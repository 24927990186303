<template>
  <div class="orders_table" width="900">
    <h1>Receiving View</h1>
    <canvas id="mainCanvas" width="650" height=400></canvas>
  </div>
</template>
<script>

export default {
  name: 'Demo01',
  props: {
    msg: String
  },
  components: {
  },
  data() {
      return {
          operators: [],
          bays: [],
          colors: ['red', 'green', 'blue', 'cyan', 'yellow', 'fuchsia', 'orange', 'crimson', 'black', 'chartreuse']
      }
  },
  methods: {
      getOperators: function() {
        const appsUrl = 'http://' + this.$host + ':' + this.$pickingPort + '/User/GetUserLocations'
            this.$http.get(appsUrl)
                .then((result) => {
                    this.operators = result.data;
                    this.drawCanvas();
                })
        },

      drawCanvas: function() {
      },
  },
  mounted: function () {
      this.getOperators();
  }  
}
</script>