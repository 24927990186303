<template>
  <div>
    <h2>Order Lookup</h2>
    <v-text-field
        v-model="orderId"
        @input="fetchOrder"
        placeholder="Order ID"
        autofocus></v-text-field>
    <v-stepper>
      <v-stepper-header>
        <v-stepper-step step="1" :complete="orderStatus == 0">Created</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="2" :complete="orderStatus > 0">Released</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="3" :complete="orderStatus > 1">Completed</v-stepper-step>
      </v-stepper-header>
    </v-stepper>
    <br>
    <v-data-table class="table table-bordered"
      :items="order"
      :headers="orderHeaderFields"
      head-variant="light"
      :busy="isBusy">
      <template
          v-slot:item.latestShipTime="{ item }"
      >
        {{ formatDate(item.latestShipTime)}}
      </template>
      <template
        v-slot:item.state="{ item }"
      >
        {{ formatState(item.state) }}
      </template>
    </v-data-table>
    <h3>Items</h3>
    <v-data-table striped hover class="table table-bordered"
      :items="orderItems"
      :headers="orderItemHeaderFields"
      head-variant="light"
      :busy="isBusy">
      <template
          v-slot:item.skuId="{ item }"
      >
        <v-row v-on:click="navToInventory(item.skuId)">
          {{ item.skuId }}
        </v-row>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import StepProgress from 'vue-step-progress';
import 'vue-step-progress/dist/main.css';
import * as orders from '../js/orders.js';
import Toolbar from './Base/OverviewToolbar.vue';

export default {
  components: {
    'step-progress': StepProgress
  },
  name: 'OrderDetails',
  props: {
  },
  data() {
    return {
          orderId: '',
          orderStatus: 0,
          order: [],
          orderItems: [],
          orderHeaderFields: [
            { value: 'orderId', text: 'Order' },
            {
              value: 'state', text: 'Status',
              formatter: (value, key, item) => {
                return this.formatState(value);
              }
            },
            { value: 'priority', text: 'Priority' },
            {
              value: 'latestShipTime', text: 'Ship Time',
              formatter: (value, key, item) => {
                return this.formatDate(value);
              },
            }
          ],
          orderItemHeaderFields: [
            { value: 'skuId', text: 'SKU' },
            { value: 'qtyRequested', text: 'Quantity' },
            { value: 'locationId', text: 'Location' },
            { value: 'zoneId', text: 'Zone' }
          ],
          createdStateEnabled: '',
          releasedStateEnabled: '',
          pickingCompletedStateEnabled: '',
          putwallCompletedStateEnabled: '',
          packingCompletedStateEnabled: '',
          completedStateEnabled: '',
          breadcrumbs: [
            {text: 'Home', href: '/'}, 
            {text: 'Operations'}, 
            {text: 'Order Lookup'}
          ],
          isBusy: true
      }
  },
  methods: {
    formatState: function(state) {
      return orders.getStateStringFormat(state);
    },
    fetchOrder: function() {
      this.getOrderFromServer();
    },
    getOrderFromServer: function() {
      const appsUrl = 'http://' + this.$host + ':' + this.$ordersPort + '/api/order-management/Order/' + this.orderId
        this.$http.get(appsUrl)
          .then((result) => {
              this.order = [];
              if (result.data == null || result.data == "") {
                return;
              }

              if (result.data.orderId == null || result.data.orderId == "") {
                return;
              }

              this.order.push(result.data);
              this.orderItems = this.order[0].orderItems;
              this.formatState(this.order[0].state);
          });
    },
    formatDate(value) {
      let date = new Date(value);
      return date.toLocaleString();
    },
    navToInventory(skuId) {
      location.href = '#/inventoryLookup/' + skuId;
    },
  },
  mounted: function () {
    this.orderId = this.$route.params.orderId
    if (this.orderId == null || this.orderId === '') {
      return;
    }

    this.getOrderFromServer();
  }  
}
</script>