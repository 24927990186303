/**
 * Returns a string based state based on the enum / int provided value.
 * @param state
 * @returns {string}
 */
export function getStateStringFormat(state) {
    if (state == 0) {
        return 'Created';
    } else if (state == 1) {
        return 'Pending Release';
    } else if (state == 2) {
        return 'Released';
    } else if (state == 3) {
        return 'Picking Complete';
    } else if (state == 7) {
        return 'On Hold';
    } else {
        return 'Complete';
    }
}

/**
 * Gets and returns a list of orders from the backend service.
 */
export function getOrders() {
    configurations.getHostUrl();
}