<template>
  <v-container fluid>
    <v-row>
      <v-col md="10" offset-md="1">
        <v-card
            class="pa-2"
            shaped>
          <v-card-title>Products</v-card-title>
          <v-card-text>
            <v-row>
              <v-spacer></v-spacer>
              <v-btn
                @click="newItem"
                small
                color="grey-darken-3"
                dark
                class="ma-2 white--text"
              >
                <v-icon
                  left
                  dark
                >
                  mdi-plus
                </v-icon>
                New Item
              </v-btn>
            </v-row>
            <v-row>
              <v-col
              >
                <v-data-table
                    :items="purchaseOrderItems"
                    :headers="headerFields"
                    loading-text="Loading... Please wait"
                    dense
                    class="elevation-1">
                  <template v-slot:item.actions="{ item }">
                    <v-icon
                        small
                        class="mr-2"
                        @click="editItem(item)"
                    >
                      mdi-pencil
                    </v-icon>
                    <v-icon
                        small
                        @click="deleteItem(item)"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import * as configurations from "@/js/configurations";

export default {

  name: 'PurchaseOrderItems',
  props: {
    purchaseOrderItems: [],
  },
  data () {
    return {
      headerFields: [
        { value: "itemCode", text: "Item",  },
        { value: "description", text: "Description" },
        { value: "productName", text: "Product" },
        { value: "quantity", text: "Quantity" },
        { value: "unitPrice", text: "Unit Price" },
        { value: 'actions', text: 'Actions', sortable: false }
      ],
      dialogDelete: false,
    }
  },
  methods: {
    newItem: function() {
      let newItem = {};
      newItem.itemCode = "SKU00001";
      newItem.productName = "Awesome Product";
      newItem.description = "Cool description";
      newItem.quantity = 1;
      newItem.unitPrice = 5.65;

      this.purchaseOrderItems.push(newItem);
    },
    deleteItem (item) {
      this.editedIndex = this.purchaseOrderItems.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },
  },
  mounted: async function() {
  }
}
</script>

<style scoped>

</style>