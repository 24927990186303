<template>
    <div class="container">
        <h3>Demo Stats</h3>
        <v-data-table width="300" :bordered=true responsive='sm' :small=true :items='demoStats'>
        </v-data-table>
    </div>
</template>
<style>
</style>
<script>
export default {
    name: 'DemoStats',
    data () {
        return {
            demoStats: []
        }
    },
    methods: {
    
    },
    mounted: function () {   
        var totalSkus = {
            title: 'Total SKUs',
            value: '10,000'
        };

        var inventoryOnHand = {
            title: 'Inventory on hand',
            value: '53,000'
        };

        var availableDeliveries = {
            title: 'Available deliveries',
            value: 3
        };

        var completedDeliveries = {
            title: 'Completed deliveries',
            value: 8
        };

        var activeOrders = {
            title: 'Active orders',
            value: 126
        };

        var completedOrders = {
            title: 'Completed orders',
            value: 1262
        };

        var averageThroughput = {
            title: 'Hourly order throughput',
            value: 721
        };

        var activeOperators = {
            title: 'Active operators',
            value: 10
        };

        this.demoStats.push(totalSkus);
        this.demoStats.push(inventoryOnHand);
        this.demoStats.push(availableDeliveries);
        this.demoStats.push(completedDeliveries);
        this.demoStats.push(activeOrders);
        this.demoStats.push(completedOrders);
        this.demoStats.push(averageThroughput);
        this.demoStats.push(activeOperators);
    }
}
</script>
<style>
</style>