<template>
  <div>
    <v-card>
      <v-toolbar
          color="grey"
          dark
          dense
          flat
      >
        <v-toolbar-title class="text-md-body-1">
          {{ title }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn
            small
            disabled
            color="grey-darken-3"
            class="ma-2 white--text"
        >
          <v-icon
              left
              dark
          >
            mdi-pencil
          </v-icon>
          Create</v-btn>
      </v-toolbar>
      <v-data-table
          :items="locations"
          :headers="headers"
          :search="search"
          no-data-text="No data"
          :loading="isLoading"
          loading-text="Loading... Please wait"
          dense
          class="elevation-1">
      </v-data-table>
    </v-card>
  </div>
</template>

<script>

export default {
  name: 'WarehouseLocations',
  props: {
    msg: String
  },
  components: {
  },
  data() {
    return {
      locations: [],
      search: '',
      isLoading: true,
      title: 'Warehouse Locations',
      headers: [
        { text: 'Location', value: 'fullLocation' },
        { text: 'Display', value: 'displayId' },
        { text: 'Sequence', value: 'sequence' },
        { text: 'Height', value: 'height' },
        { text: 'Width', value: 'width' },
        { text: 'Depth', value: 'depth' },
        { text: 'Area', value: 'area' },
        { text: 'Transfer Area', value: 'transferArea' },
        { text: 'Type', value: 'locationType' },
        { text: 'Storage Condition', value: 'storageCondition' },
      ]
    }
  },
  methods: {
    createConfiguration: function() {
      location.href = '#/administration/Configurations/createConfiguration';
    }
  },
  mounted: function () {
    const appsUrl = 'http://' + this.$host + ':' + this.$configurationServicePort + '/api/warehouse-configuration/Topology/Location';
    this.$http.get(appsUrl)
        .then((result) => {
          this.locations = result.data;
          this.isLoading = false;
        });
  }
}
</script>