<template>
  <div>
    <v-alert
        v-show="isAlertVisible"
        dismissible
    >
      <p>{{ tempMessage }}</p>
    </v-alert>
    <div>
      <v-alert v-model="showTempMessage" :variant="tempMessageVariant">
          {{ tempMessage }}
      </v-alert>
        <v-toolbar
            color="grey"
            dark
            dense
            flat
        >
          Confirm Container {{ receivingContainer.licensePlateNumber }}
        </v-toolbar>
      <v-list-item>
          <v-list-item-content>
              <v-list-item-title>Delivery: {{ receivingContainer.taskId }}</v-list-item-title>
          </v-list-item-content>
      </v-list-item>
      <v-list-item>
          <v-list-item-content>
              <v-list-item-title>Total items: {{ itemCount }}</v-list-item-title>
          </v-list-item-content>
      </v-list-item>
      <v-btn-toggle>
        <v-btn v-on:click="acceptDeliveryContainer()"
               v-on:keyup.enter="acceptDeliveryContainer()"
               dark
               color="grey-darken-3"
               dense
        >
          Accept Container
        </v-btn>
        <v-btn
            v-on:click="rejectDeliveryContainer()"
        >
          Reject Container
        </v-btn>
      </v-btn-toggle>
    </div>
  </div>
</template>

<script>
export default {
    name: 'ConfirmDeliveryContainer',
    props: {
        msg: String
    },
    data() {
        return {
          userId: 'test1',
          zoneId: 'zone1',
          containerId: '',
          currentState: '',
          tempMessage: '',
          receivingContainer: '',
          itemCount: 0,
          showTempMessage: false,
          tempMessageVariant: "success",
          isAlertVisible: false,
          variant: ''
        }
    },
    methods: {
        acceptDeliveryContainer: async function() {
            const deliveryContainerId = this.receivingContainer.licensePlateNumber;
            const appsUrl = 'http://' + this.$host + ':' + this.$receivingPort +
                '/api/receiving/ReceivingContainer/' + deliveryContainerId + '/accept';
            try {
              const response = await this.$http.put(appsUrl);
              location.href = "#/delivery/dropOffDeliveryContainer/" + deliveryContainerId;
            } catch (error) {
              if (error.response) {
                console.error(error.response.data);
              } else if (error.request) {
                console.error(error.request);
              } else {
                console.error('Error: ', error.message);
              }
            }
        },
        rejectDeliveryContainer: function() {
        },
        getDeliveryContainer: async function(deliveryContainerId) {
            const appsUrl = 'http://' + this.$host + ':' + this.$receivingPort +
                '/api/receiving/ReceivingContainer/' + deliveryContainerId;
            try {
              const response = await this.$http.get(appsUrl);
              this.receivingContainer = response.data;
              this.itemCount = this.receivingContainer.items.length;
            } catch(error) {
              if (error.response) {
                this.tempMessage = 'Need to identify a container.';
              } else if (error.request) {
                console.error(error.request);
              } else {
                console.error('Error: ', error.message);
              }
            }
        }
    },
    mounted: function() {
        let deliveryContainerId = this.$route.params.deliveryContainerId;
        this.getDeliveryContainer(deliveryContainerId);
    }
}
</script>