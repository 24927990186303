<template>
  <div>
    <v-card>
      <v-toolbar
          color="grey"
          dark
          dense
          flat
      >
        <v-toolbar-title class="text-md-body-1">
          {{ title }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
        ></v-text-field>
        <v-spacer></v-spacer>
        <v-btn
            small
            disabled
            color="grey-darken-3"
            class="ma-2 white--text"
        >
          <v-icon
              left
              dark
          >
            mdi-pencil
          </v-icon>
          Create</v-btn>
      </v-toolbar>
      <v-card-title
          class="text-h6"
      >
      </v-card-title>

      <v-data-table
          :items="putaways"
          :headers="headers"
          :search="search"
          :key="putaways.putawayId"
          no-data-text="No data"
          :loading="isLoading"
          loading-text="Loading... Please wait"
          dense
          class="elevation-1">
        <template v-slot:item.status="{ item }">
          <v-chip
              :color="getStateColor(item.status)"
              dark
              small
          >
            {{ formatState(item.status) }}
          </v-chip>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
export default {
  name: 'Putaways',
  props: {
    msg: String
  },
  data() {
      return {
        putaways: [],
        headers: [
          { value: 'putawayId', text: 'Putaway'},
          { value: 'putawayCartId', text: 'Cart', sortable: true },
          { value: 'status', text: 'Status' },
          { value: 'skuId', text: 'SKU', sortable: true },
          { value: 'currentLocation', text: 'Origin Location', sortable: true },
          { value: 'transferLocation', text: 'Transfer Location', sortable: true },
          { value: 'quantityRequested', text: 'Requested', sortable: true },
          { value: 'quantityConfirmed', text: 'Confirmed', sortable: true }
        ],
        breadcrumbs: [
          { text: "Home", href: "/" },
          { text: "Operations" },
          { text: "Putaways" }
        ],
        currentPage: 1,
        totalRows: 1,
        rowsPerPage: 20,
        filter: null,
        title: "Putaways",
        search: '',
        isLoading: true,
      }
  },
  methods: {
      navToOrderDetails: function(orderId) {
        location.href = '#/order/' + orderId;
      },
      formatDate(value) {
        var date = new Date(value);
        return date.toLocaleString();
      },
      onFiltered(filteredItems) {
        this.totalRows = filteredItems.length;
        this.currentPage = 1;
      },
      formatState: function(state) {
        console.log("State: ", state);
        if (state === 0) {
          return 'Created';
        } else if (state === 1) {
          return 'Released';
        } else if (state === 2) {
          return 'Assigned';
        } else if (state === 3) {
          return 'Inducted';
        } else if (state === 4) {
          return 'Suspended';
        } else if (state === 5) {
          return 'Completed';
        } else {
          return 'Unknown';
        }
      },
      getStateColor: function(state) {
        if (state === 0) {
          return 'blue';
        } else if (state === 1) {
          return 'teal';
        }

        return 'green';
      },
  },
  mounted: function () {
      const appsUrl = 'http://' + this.$host + ':' + this.$putawayPort + '/api/putaway/Putaway';
      this.$http.get(appsUrl)
        .then((result) => {
          this.putaways = result.data;
          this.totalRows = this.putaways.length;
        })
        .catch(function(error) {
          console.error('An error occurred while retrieving putaways: ', error.toJSON());
        });

      this.isLoading = false;
  }  
}
</script>