<template>
  <v-card>
    <v-card-title class="text-md-body-1">
      Configurations
    </v-card-title>
    <v-card-text>
      <v-data-table
          :items="configurations"
          :headers="headers"
          :search="search"
          no-data-text="No data"
          :loading="isLoading"
          hide-default-footer
          loading-text="Loading... Please wait"
          dense
          class="elevation-1">
        <template v-slot:item.value="props">
          <v-edit-dialog
              :return-value.sync="props.item.value"
          >
            {{ props.item.value }}
            <template v-slot:input>
              <v-text-field
                  v-model="props.item.value"
                  label="Edit"
                  single-line
                  counter
              ></v-text-field>
            </template>
          </v-edit-dialog>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<style>
</style>
<script>
export default {
  name: 'Configuration',
  data () {
    return {
      configurations: [],
      isLoading: true,
      search: '',
      headers: [
        { value: "friendlyName", text: "Name" },
        { value: 'defaultValue', text: 'Default' },
        { value: 'value', text: 'Value' },
        { value: "description", text: "Description" },
        { value: 'category', text: 'Category' },
      ],

    }
  },
  methods: {
    getConfigurations: async function() {
        const appsUrl = 'http://' + this.$simulationUri + ':' + this.$simulationPort + '/configurations';
        let response = await this.$http.get(appsUrl);
        this.configurations = response.data;
        console.log(this.configurations);
        this.isLoading = false;
    },
    updateConfigurations: function() {
      const appsUrl = 'http://' + this.$simulationUri + ':' + this.$simulationPort + '/configurations';
      console.log('Configurations from update: ', this.configurations);
      this.$http.post(appsUrl, this.configurations);
    }
  },
  mounted: async function () {
    await this.getConfigurations();
  }
}
</script>
<style>
</style>