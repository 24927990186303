<template>
  <div>
    <h2>{{ title }}</h2>
    <v-data-table
        :headers="headers"
        :items="putaways">
      <template v-slot:item.status="{ item }">
        <v-row>
          {{ formatState(item.status) }}
        </v-row>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  name: 'Putaways',
  props: {
    msg: String
  },
  data() {
    return {
      putaways: [],
      headers: [
        { value: 'title', text: 'Title', sortable: true},
        { value: 'description', text: 'Description', sortable: true },
        { value: 'status', text: 'Status', sortable: true }
      ],
      isBusy: true,
      breadcrumbs: [
        { text: "Home", href: "/" },
        { text: "Operations" },
        { text: "Putaways" }
      ],
      currentPage: 1,
      totalRows: 1,
      rowsPerPage: 20,
      filter: null,
      title: "Products",
    }
  },
  methods: {
    navToOrderDetails: function(orderId) {
      location.href = '#/order/' + orderId;
    },
    formatDate(value) {
      var date = new Date(value);
      return date.toLocaleString();
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    formatState: function(state) {
      if (state === 0) {
        return 'Created';
      } else if (state === 1) {
        return 'Released';
      } else if (state === 2) {
        return 'Assigned';
      } else if (state === 3) {
        return 'Inducted';
      } else if (state === 4) {
        return 'Suspended';
      } else if (state === 5) {
        return 'Completed';
      } else {
        return 'Unknown';
      }
    },
  },
  mounted: function () {
    const appsUrl = 'http://' + this.$host + ':' + this.$inventoryPort + '/api/inventory-management/products';
    this.$http.get(appsUrl)
        .then((result) => {
          this.putaways = result.data;
          this.isBusy = false;
          this.totalRows = this.putaways.length;
        })
        .catch(function(error) {
          console.error('An error occurred while retrieving the products: ', error.toJSON());
        })
  }
}
</script>